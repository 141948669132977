import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import css from './style.module.css'
import { Link, useNavigate } from 'react-router-dom';

function ContactForm() {
  const { register, formState: { errors }, setValue, handleSubmit } = useForm({ mode: 'onBlur' });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (data) {
      try {
        axios.get(
          `${process.env.REACT_APP_BACKEND_PORT}/clientPhone/`,
          {
            params: {
              pid: 104401,
              msisdn: data.phoneNumber,
            },
          },
        );

        navigate('/success');
      } catch (error) {
        alert('Error making POST request:', error);
      }
    }
  };

  const handleTelInputFocus = () => {
    setValue('phoneNumber', '7');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>
        Фамилия*:
        <input aria-invalid={errors.lastName ? "true" : "false"}
               type="text" {...register('lastName', { required: true })} />
        {errors.lastName?.type === 'required' &&
          <p className={css.error} role="alert">Это поле является обязательным</p>}
      </label>
      <label>
        Имя*:
        <input aria-invalid={errors.firstName ? "true" : "false"}
               type="text" {...register('firstName', { required: true })} />
        {errors.firstName?.type === 'required' &&
          <p className={css.error} role="alert">Это поле является обязательным</p>}
      </label>
      <label>
        Отчество:
        <input type="text" {...register('patronymic')} />
      </label>
      <label>
        Номер телефона*:
        <input aria-invalid={errors.phoneNumber ? "true" : "false"} type="number"
               onFocus={handleTelInputFocus} {...register('phoneNumber', { required: true,
            minLength: { value: 11 } })} />
        {errors.phoneNumber?.type === 'required' &&
          <p className={css.error} role="alert">Это поле является обязательным</p>}
        {errors.phoneNumber?.type === 'minLength' &&
          <p className={css.error} role="alert">Введите корректный номер телефона</p>}
      </label>
      <label>
        Дата рождения*:
        <input aria-invalid={errors.birthDate ? "true" : "false"} type="text" placeholder='ДД.ММ.ГГГГ'
               pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" {...register('birthDate', {
          required: true,
          pattern: /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
        })} />
        {errors.birthDate?.type === 'required' &&
          <p className={css.error} role="alert">Это поле является обязательным</p>}
        {errors.birthDate?.type === 'pattern' &&
          <p className={css.error} role="alert">Введите дату в формате ДД.ММ.ГГГГ</p>}
      </label>
      <label>
        Email*:
        <input type="email" {...register('email')} />
      </label>

      <p>Нажимая кнопку "Отправить" Вы даете свое согласие с
          <a href='/personal_data_policy.pdf' target="_blank"> Политикой
          обработки персональных данных
          </a>
      </p>
      <label className={css.in_check}>
        <input type="checkbox" {...register('personalDataAgreement', { required: 'Это поле является обязательным' })} />
          <div>
              <span>Я даю своё согласие на обработку моих персональных данных</span>
              <a href='/personal_data_agreement.pdf' target="_blank">
                  {`(Согласие на обработку персональных данных)`}
              </a>
          </div>
      </label>
        {errors.personalDataAgreement &&
            <span className={css.check_error} role="alert">{errors.personalDataAgreement.message}</span>}

        <label className={css.in_check}>
            <input type="checkbox" {...register('sendingAgreement')} />
            <div>
              <span>
            {`Я даю своё согласие на получение мной рекламно-информационных материалов `}
        </span><a href='/sending_agreement.pdf' target="_blank">
                {`(Согласие на рассылку)`}
            </a>
            </div>
        </label>

        <button type="submit">Отправить</button>
    </form>
  );
}

export default ContactForm;
